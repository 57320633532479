import axios, { AxiosResponse } from 'axios';
import { GPXData, Waypoint, Totals } from '../types/types-gpx';


function distanceBetweenCoords(coord1:[number, number], coord2:[number, number], units:string) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(coord2[0] - coord1[0]);  // deg2rad below
  var dLon = deg2rad(coord2[1]-coord1[1]);
  var a =
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(coord1[0])) * Math.cos(deg2rad(coord2[0])) *
    Math.sin(dLon/2) * Math.sin(dLon/2)
    ;
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  var d = R * c; // Distance in km
  // units can be metric or imperial
  return (units==='metric') ? d : d*0.621371;
}

function deg2rad(deg:number) {
  return deg * (Math.PI/180)
}

function getTrackArray(gpxStr: string):string[] {
    // Count lat & lon
    var matches = gpxStr.match(/<trk>/g);
    var trkCount = matches ? matches.length : 0;

    // While loop params
    var i = 0;
    var tracksArray = [];
    
    while (i < trkCount) {
    // Number of total characters
    let totalGpxStr = gpxStr.length;

    // Slice str to each <trk></trk> segment
    let trkPosition = gpxStr.indexOf("<trk>");
    let trkLastPosition = gpxStr.indexOf("</trk>") + "</trk>".length;
    let trkStr = gpxStr.substring(trkPosition, trkLastPosition);

    // If <trk></trk> is existing
    if (trkPosition > 0) {
      // Redefine the native string
      gpxStr = gpxStr.substring(trkLastPosition, totalGpxStr);

      tracksArray.push(trkStr);
    }

    // Incrementation
    i++;
  }
  return tracksArray;
}

function getCoordsAtts(selectedStr: string):number[] {
  // Get latitude and longitude between double quotes from the string
  var reg = new RegExp(/"(.*?)"/g); // Double quotes included
  var matches = selectedStr.match(reg);
  var matchesArr = [];

  // Record matches
  if(matches !== null) {
    for (let match of matches) {
      // Match convert to number format
      let v = parseFloat(match.replace(/['"]+/g, ""));

      // Record
      matchesArr.push(v);
    }
  }
  return matchesArr;
}

function getWaypointsArray(gpxStr:string):Waypoint[] {
  var w = 0;
  var wptArr: Waypoint[] = [];
  var matches = gpxStr.match(/wpt lat=/g)
  var waypointCount = matches ? matches.length : 0;
  
  while (w < waypointCount) {
    // Number of total characters
    let totalStr = gpxStr.length;
    
    // Selection of string
    var selectedStrPosition:number = gpxStr.indexOf("<wpt");
    var selectedStrLastPosition:number = gpxStr.indexOf("</wpt>");
    var selectedStr:string = gpxStr.substring(
      selectedStrPosition,
      selectedStrLastPosition
    );

    var name:string = getElementContent(gpxStr, "name");
    var type:string = getElementContent(gpxStr, "type");

    var coordsArr = getCoordsAtts(selectedStr);
    // Latitude / Longtitude values
    let latValue = coordsArr[0];
    let lonValue = coordsArr[1];

    let wpt:Waypoint = { name: name, type: type, position: [latValue, lonValue]};
    wptArr.push(wpt);
    
    if (selectedStrPosition > 0) {
      // Redefine the native string
      gpxStr = gpxStr.substring(selectedStrLastPosition + 5, totalStr);
    }
    w++;
  }
  //
  //console.log("number of waypoints = " + waypointCount);
  return wptArr;
}

function getElementContent(element:string, tag:string):string {
  var strPos = element.indexOf("<" + tag + ">") + ("<" + tag + ">").length;
  var strLastPos = element.indexOf("</" + tag + ">");
  return element.substring(
    strPos,
    strLastPos
  );
}

const gpxFunctions = {
// Get max and min number from array of arrays
  async getTotals(array: number[][]): Promise<Totals>  {
    return new Promise<Totals>(async (resolve, reject) => {
      try {
        // Params
        let resultArray: number[] = [];
        let elGain: number = 0;
        let elLoss: number = 0;
        let elPrev: number = 0;
        let elNet: number = 0;
        let distance: number = 0;

        array.forEach((element, i) => {
          resultArray.push(element[2]);
          
          // calc cumlative stats on all apart from first item
          if(i>0) {
            let tmpElGain:number = element[2]-elPrev;
            // if new point is higher than old, then add it!
            elGain += tmpElGain>=0 ? tmpElGain : 0;
            elLoss += tmpElGain<=0 ? tmpElGain : 0;
            elNet += tmpElGain;
            distance = element[3];
          }

          elPrev = element[2];

          // on last iteration, store the values
          if (i + 1 === array.length) {
            // Get the min and max number from array
            let max = Math.max(...resultArray);
            let min = Math.min(...resultArray);

            // Result object
            let obj:Totals = {
              maxElevation: max,
              minElevation: min,
              elevationGain: elGain,
              elevationLoss: elLoss,
              elevationNet: elNet,
              distance: distance
            };

            resolve(obj);
          }
        });
      } catch (error) {
        console.error(":( getTotals error");
        reject(console.log);
      }
    });
  },

  // Parse gpx file
  async parseGpxFile(gpxXmlFile: string, units: string) {
    return new Promise(async (resolve, reject) => {
      try {
        axios
          .get(gpxXmlFile, {
            headers: {
              "Content-Type": "application/xml; charset=utf-8"
            }
          })
          .then(async (response: AxiosResponse) => {
            // Gpx file string
            var gpxStr = response.data;

            var waypointsArr = getWaypointsArray(gpxStr);
            var tracksArray = getTrackArray(gpxStr);

            // Foreach loop params
            var tracksObjArray = [];

            // for each track
            for (let f = 0; f < tracksArray.length; f++) {
              // Variables params
              var track = tracksArray[f];

              // Track's name
              let trackName = getElementContent(tracksArray[f], "name");
              
              // Params
              var n = 0;
              var pointArr = [];

              // Count lat & lon
              var trackMatches = tracksArray[f].match(/lat=/g);
              var latLonCount = trackMatches ? trackMatches.length : 0;

              var oldLatValue:number = 0;
              var oldLonValue:number = 0;
              var distance:number = 0;

              // for each point
              while (n < latLonCount) {
                // Number of total characters
                let totalStr = track.length;

                // Selection of string
                var selectedStrPosition = track.indexOf("<trkpt");
                var selectedStrLastPosition = track.indexOf("</trkpt>");
                var selectedStr = track.substring(
                  selectedStrPosition,
                  selectedStrLastPosition
                );

                var selectedElevationsStr = getElementContent(track, "ele");
                var eleValue = parseFloat(selectedElevationsStr) * (units==='metric' ? 1 : 3.280839895);

                // Get latitude and longitude between double quotes from the string
                var coordsArr = getCoordsAtts(selectedStr);
                // Latitude / Longtitude values
                let latValue = coordsArr[0];
                let lonValue = coordsArr[1];

                distance += oldLatValue===0 && oldLonValue===0 ? 0 : distanceBetweenCoords([oldLatValue, oldLonValue], [latValue, lonValue], units);

                // If <trkpt></trkpt> is existing
                if (selectedStrPosition > 0) {
                  // Redefine the native string
                  track = track.substring(selectedStrLastPosition + 5, totalStr);

                  // Record
                  pointArr.push([latValue, lonValue, eleValue, distance]);
                }

                // Incrementation
                oldLatValue = latValue;
                oldLonValue = lonValue;
                n++;

                // While loop end
                if (n === latLonCount) {
                  // Remove duplicated values
                  let stringArray = pointArr.map((value) => JSON.stringify(value));
                  let uniqueStringArray = new Set(stringArray);
                  let uniqueArray = Array.from(uniqueStringArray, (value) => JSON.parse(value));

                  // Min and max elevations
                  const totals = await gpxFunctions.getTotals(
                    uniqueArray
                  );
                  
                  // Result object
                  let track: {name: string, positions: number[], totals: Totals, waypoints: Waypoint[]} = {
                    name: trackName,
                    positions: uniqueArray,
                    totals: totals,
                    waypoints: []
                  };

                  if(f===0) {
                      track.waypoints = waypointsArr;
                  }

                  // Record
                  tracksObjArray.push(track);
                }
              }

              // For loop end
              if (f + 1 === tracksArray.length) {
                resolve(tracksObjArray);
              }
            }
          });
      } catch (error) {
        console.error(":( parseGpxFile error");
        reject(console.log);
      }
    });
  },

  // Merge elevations and co-ords from multiple tracks contained into a single gpx file
  async mergeTracks(parseGpxData: GPXData[]) {
    return new Promise(async (resolve, reject) => {
      try {
        // Params
        var a: number[][] = [];

        parseGpxData.forEach((element: GPXData, i: number) => {
          // Positions record
          a.push(element.positions);

          // Foreach loop end
          if (i + 1 === parseGpxData.length) {
            resolve(a);
          }
        });
      } catch (error) {
        console.error(":( mergeElevation error");
        reject(console.log);
      }
    });
  }

}

export default gpxFunctions;