
import './App.scss';
import CaderFactAppWrapper from './components/caderFactApp';

function App() {
  return (
    <div className="App">
      <CaderFactAppWrapper/>
    </div>
  );
}

export default App;
