import { useState, useEffect } from 'react';

import { locationData } from '../types/types-caderFact';
import { format, isValid } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun } from '@fortawesome/free-solid-svg-icons';
import SunCalc from 'suncalc';

const SunTimes = (props: { date: Date, locations: locationData[] }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState<string | null>(null);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        if (props.locations && props.locations.length > 0) {
            setSelectedLocation(props.locations[0].display);
        }
    }, [props.locations]);

    return (
        <>
        {props.locations.length > 0 ? (
            <div className="tile primary w3-border">
                <div className="title" role="button" tabIndex={0} onClick={toggleExpand}>
                    <FontAwesomeIcon aria-hidden="true" icon={faSun} className="icon" />
                    <h3>Solar times</h3>
                </div>
                <div className={`content ${isExpanded ? 'expanded' : ''}`}>
                    {props.locations.length > 1 && (
                        <nav id="solar-menu" className="w3-bar sub-menu secondary">
                            {props.locations.map((item, index) => (
                                <button key={index} onClick={() => setSelectedLocation(item.display)} className="w3-bar-item w3-button">{item.display.split(',')[0]}</button>
                            ))}
                        </nav>
                    )}

                    {selectedLocation && props.locations.map((location, index) => {
                        if (location.display === selectedLocation) {
                            const times = SunCalc.getTimes(props.date, location.lat, location.long);

                            // Function to format the date and time
                            const formatTime = (date: Date | undefined) => {
                                return date && isValid(date) ? format(date, 'HH:mm') + ' UTC' : 'N/A';
                            };

                            return (
                                <div key={index}>
                                    <h4>{location.display}</h4>
                                    <dl>
                                        <div className="w3-row">
                                            <dt>Night ends (morning astronomical twilight starts)</dt>
                                            <dd>{formatTime(times.nightEnd)}</dd>
                                        </div>
                                        <div className="w3-row">
                                            <dt>Nautical dawn (morning nautical twilight starts)</dt>
                                            <dd>{formatTime(times.nauticalDawn)}</dd>
                                        </div>
                                        <div className="w3-row">
                                            <dt>Dawn (morning civil twilight starts)</dt>
                                            <dd>{formatTime(times.dawn)}</dd>
                                        </div>
                                        <div className="w3-row">
                                            <dt>Sunrise</dt>
                                            <dd>{formatTime(times.sunrise)}</dd>
                                        </div>
                                        <div className="w3-row">
                                            <dt>Morning golden hour ends</dt>
                                            <dd>{formatTime(times.goldenHourEnd)}</dd>
                                        </div>
                                        <div className="w3-row">
                                            <dt>Solar noon</dt>
                                            <dd>{formatTime(times.solarNoon)}</dd>
                                        </div>
                                        <div className="w3-row">
                                            <dt>Evening golden hour starts</dt>
                                            <dd>{formatTime(times.goldenHour)}</dd>
                                        </div>
                                        <div className="w3-row">
                                            <dt>Sunset (civil twilight starts)</dt>
                                            <dd>{formatTime(times.sunset)}</dd>
                                        </div>
                                        <div className="w3-row">
                                            <dt>Dusk (evening nautical twilight starts)</dt>
                                            <dd>{formatTime(times.dusk)}</dd>
                                        </div>
                                        <div className="w3-row">
                                            <dt>Nautical dusk (evening astronomical twilight starts)</dt>
                                            <dd>{formatTime(times.nauticalDusk)}</dd>
                                        </div>
                                        <div className="w3-row">
                                            <dt>Night starts (dark enough for astronomical observations)</dt>
                                            <dd>{formatTime(times.night)}</dd>
                                        </div>
                                    </dl>
                                </div>
                            );
                        } else {
                            return null;
                        }
                    })}
                </div>
            </div>
            ) : null}
        </>
    );
};

export default SunTimes;
