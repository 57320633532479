import { LayerGroupData, PolylineInfo, MarkerInfo } from '../types/types-map';
import { Totals } from '../types/types-gpx';
import { caderDay, caderRoute, locationData, CaderFactData } from '../types/types-caderFact';

import axios from 'axios';

import gpxFunctions from "../functions/gpx";

const startMarkerColor: string = 'green';
const middleMarkerColor: string = 'yellow';
const finishMarkerColor: string = 'red';

export async function convertUnits(caderFactData:CaderFactData, units: string) {
    for (let day of caderFactData.days) {
        if(day.routes) {
            for (let route of day.routes) {
                if (route.coords) {
                    for (let item of route.coords) {
                        if (Array.isArray(item)) {
                            for (let coord of item) {
                                if (Array.isArray(coord)) {
                                    coord[2] = units === 'metric' ? coord[2] * 0.3048 : coord[2] / 0.3048;
                                    coord[3] = units === 'metric' ? coord[3] * 1.60934 : coord[3] / 1.60934;
                                }
                            }
                        }
                    }
                }
                route.totals!.maxElevation = units === 'metric' ? route.totals!.maxElevation * 0.3048 : route.totals!.maxElevation / 0.3048;
                route.totals!.minElevation = units === 'metric' ? route.totals!.minElevation * 0.3048 : route.totals!.minElevation / 0.3048;
                route.totals!.elevationGain = units === 'metric' ? route.totals!.elevationGain * 0.3048 : route.totals!.elevationGain / 0.3048;
                route.totals!.elevationLoss = units === 'metric' ? route.totals!.elevationLoss * 0.3048 : route.totals!.elevationLoss / 0.3048;
                route.totals!.elevationNet = units === 'metric' ? route.totals!.elevationNet * 0.3048 : route.totals!.elevationNet / 0.3048;
                route.totals!.distance = units === 'metric' ? route.totals!.distance * 1.60934 : route.totals!.distance / 1.60934;
            }
        }
    }
}

function initiateTotals() {
    return {
        maxElevation: 0,
        minElevation: 0,
        elevationGain: 0,
        elevationLoss: 0,
        elevationNet: 0,
        distance: 0
    };
}

function updateTotals(parentTotals: Totals, childTotals: Totals) {
    const properties = ['maxElevation', 'minElevation', 'elevationGain', 'elevationLoss', 'elevationNet', 'distance'];
    properties.forEach(property => {
        switch (property) {
            case 'maxElevation':
                parentTotals[property] = Math.max(childTotals[property], parentTotals[property]);
                break;
            case 'minElevation':
                parentTotals[property] = Math.min(childTotals[property], parentTotals[property]);
                break
            case 'elevationLoss':
                parentTotals[property] -= childTotals[property];
                break;
            case 'elevationGain':
            case 'elevationNet':
            case 'distance':
                parentTotals[property] += childTotals[property];
                break;
        }
    });
}

export const reCalcTotals = async (caderFactData:CaderFactData, setRouteTotals: React.Dispatch<React.SetStateAction<Totals>>) => {
    let distanceThreshold: number = 0.5;
    caderFactData.elevationData = {
        days: { datasets: [] },
        routes: { datasets: [] },
    };

    caderFactData.coords = [];
    caderFactData.totals = initiateTotals();
    caderFactData.waypoints = [];
    caderFactData.countries = [];

    await Promise.all(caderFactData.days.map(async (day: caderDay, dayIndex: number) => {
        day.coords = []; // Move this line outside of the inner loop
        day.totals = initiateTotals();
        day.waypoints = [];
        day.countries = [];

        if(day.routes) {
            // Populate day.coords before iterating over day.routes
            day.routes.forEach(route => {
                if (route.checked && route.coords && Array.isArray(route.coords[0])) {
                    day.coords!.push(route.coords[0]);
                }
            });
        
            await Promise.all(day.routes.map(async (route: caderRoute, routeIndex: number) => {
                if (route.checked) {
                    let threshold: number = distanceThreshold;
                    let elData: { x: number, y: number }[] = [];
                    route.coords!.forEach((coord: number[]) => {
                        coord.forEach((c: any) => {
                            if (c[3] >= threshold) {
                                elData.push({ x: c[3], y: c[2] });
                                threshold += distanceThreshold;
                            }
                        })
                    });

                    route.elevationData = {
                        label: route.name,
                        borderColor: `rgb(${day.color!.r},${day.color!.g},${day.color!.b})`,
                        backgroundColor: `rgba(${day.color!.r},${day.color!.g},${day.color!.b},0.5)`,
                        data: elData,
                        showLine: true,
                        elements: {
                            point: {
                                radius: 1
                            }
                        }
                    };
                    caderFactData.elevationData!.routes.datasets.push(route.elevationData);
                    
                    if (!route.startAddress && caderFactData.settings.use_nominatim_API) {
                        const coords: any = route.coords?.[0]?.[0];
                        const startAddress:locationData = await fetchAddressFromNominatim(coords[0], coords[1]);
                        route.startAddress = startAddress;
                    }

                    if (!route.finishAddress && caderFactData.settings.use_nominatim_API) {
                        const coords: any = route.coords?.[0]?.[route.coords[0].length - 1];
                        const finishAddress:locationData = await fetchAddressFromNominatim(coords[0], coords[1]);
                        route.finishAddress = finishAddress;
                    }

                    if (routeIndex === 0) {
                        if (route.startAddress) {
                            day.startAddress = route.startAddress;
                            // Add startAddress.country to day.countries if not already present
                            if (!day.countries!.includes(route.startAddress.country)) {
                                day.countries!.push(route.startAddress.country);
                            }
                            // Add startAddress.country to caderFactData.countries if not already present
                            if (!caderFactData.countries!.includes(route.startAddress.country)) {
                                caderFactData.countries!.push(route.startAddress.country);
                            }
                        }
                    }
            
                    if (routeIndex === day.routes!.length - 1) {
                        if (route.finishAddress) {
                            day.finishAddress = route.finishAddress;
                            // Add finishAddress.country to day.countries if not already present
                            if (!day.countries!.includes(route.finishAddress.country)) {
                                day.countries!.push(route.finishAddress.country);
                            }
                            // Add finishAddress.country to caderFactData.countries if not already present
                            if (!caderFactData.countries!.includes(route.finishAddress.country)) {
                                caderFactData.countries!.push(route.finishAddress.country);
                            }
                        }
                    }
                }
                if (route.totals) {
                    updateTotals(day.totals!, route.totals);
                }
                if (route.waypoints) {
                    day.waypoints!.push(...route.waypoints);
                }
                
            }));
        }

        if (day.checked) {
            if (day.coords) {
                let threshold: number = distanceThreshold;
                let elData: { x: number, y: number }[] = [];
                day.coords!.forEach((coord: number[]) => {
                    coord.forEach((c: any) => {
                        if (c[3] >= threshold) {
                            elData.push({ x: c[3], y: c[2] });
                            threshold += distanceThreshold;
                        }
                    })
                });

                day.elevationData = {
                    label: day.name,
                    borderColor: `rgb(${day.color!.r},${day.color!.g},${day.color!.b})`,
                    backgroundColor: `rgba(${day.color!.r},${day.color!.g},${day.color!.b},0.5)`,
                    data: elData,
                    showLine: true,
                    elements: {
                        point: {
                            radius: 0
                        }
                    },
                };
                caderFactData.elevationData!.days.datasets.push(day.elevationData);

            }

            if (dayIndex === 0) {
                caderFactData.startAddress = day.startAddress;
            }
    
            if (dayIndex === day.routes!.length - 1) {
                caderFactData.finishAddress = day.finishAddress;
            }

            if (day.totals) {
                updateTotals(caderFactData.totals!, day.totals);
            }

            if (day.waypoints) {
                caderFactData.waypoints!.push(...day.waypoints);
            }
        }
        console.log("UPDATED");
    }));
}

async function fetchAddressFromNominatim(lat: number, lon: number): Promise<locationData> {
    const apiUrl = `https://caderfact.garcad.cymru/api-middleware/api-cacher.php?api=nominatim-reverse&lat=${lat}&lon=${lon}&format=json&zoom=13`;
    try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
            throw new Error(`Failed to fetch data from ${apiUrl}. Status: ${response.status}`);
        }
        
        const data = await response.json();
        return { 
            display: data.display_name,
            country: data.address?.country_code || '',
            lat:lat,
            long:lon
        };
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error; 
    }
}

export function findRouteByName(caderFactData:CaderFactData, routeName: string) {
    for (let day of caderFactData.days) {
        if(day.routes) {
            for (let route of day.routes) {
                if (route.name === routeName) {
                    return route;
                }
            }
        }
    }
    return null; // return null if no route is found with the given name
}

export function findDayByName(caderFactData:CaderFactData, dayName: string) {
    for (let day of caderFactData.days) {
        if (day.name === dayName) {
            return day;
        }
    }
    return null; // return null if no day is found with the given name
}

function getGPXLinks(file:string, garminID:string) {
    var html:string;
    html = `<li><a href="gpx/${file}" download>Download file</a>`;
    html += garminID ? ` | <a target="_blank" href="https://connect.garmin.com/modern/course/${garminID}">Garmin</a></li>` : ``;
    return html;
}

// this needs to be a string as this is loaded into mapLeaflet as string
function populateMarkerDetails(obj: any, units: string) {
    var html: string;
    html = `<h1>${obj.name}</h1>`;
    if(obj.startAddress) {
        html += `<address>${obj.startAddress.display}</address>`;
    }
    if (obj.gpxFiles) {
        html += `<ul>`;
        obj.gpxFiles.forEach((gpx: {file:string, garminID:string}) => {
            html += getGPXLinks(gpx.file, gpx.garminID);
        });
        html += `</ul>`;
    }
    if (obj.routes) {
        html += `<ul>`;
        obj.routes.forEach((route:caderRoute) => {
            route.gpxFiles.forEach((gpx: {file:string, garminID:string}) => {
                html += getGPXLinks(gpx.file, gpx.garminID);
            });
        })
        html += `</ul>`;
    }
    html += `<dl>
    <div className="w3-row"><dt>Distance</dt><dd>${obj.totals.distance.toFixed(1)} ${(units === 'metric' ? 'km' : 'miles')}</dd></div>
    <div className="w3-row"><dt>Elevation Gain</dt><dd>${obj.totals.elevationGain.toFixed(1)} ${(units === 'metric' ? 'm' : 'ft')}</dd></div>
    <div className="w3-row"><dt>Elevation Loss</dt><dd>${obj.totals.elevationLoss.toFixed(1)} ${(units === 'metric' ? 'm' : 'ft')}</dd></div>
    <div className="w3-row"><dt>Elevation Net</dt><dd>${obj.totals.elevationNet.toFixed(1)} ${(units === 'metric' ? 'm' : 'ft')}</dd></div>
    <div className="w3-row"><dt>Highest point</dt><dd>${obj.totals.maxElevation.toFixed(1)} ${(units === 'metric' ? 'm' : 'ft')}</dd></div>
    <div className="w3-row"><dt>Lowest point</dt><dd>${obj.totals.minElevation.toFixed(1)} ${(units === 'metric' ? 'm' : 'ft')}</dd></div>
    </dl>`;
    return html;
}

function populateLayer(obj: any, isFirst: boolean, isLast: boolean, units: string, dayName:string) {
    var polyline: PolylineInfo = {
        weight: 3,
        color: `rgb(${obj.color.r},${obj.color.g},${obj.color.b})`,
        gpxInfo: {
            name: obj.name,
            totals: obj.totals || initiateTotals(),
            coords: (obj.coords || []).map((coord: any) => coord.map((subArray: any) => subArray.slice(0, -2)))
        }
    };
    var layer: LayerGroupData = { name: obj.name, markers: [], polylines: [], checked: obj.checked };

    var marker: MarkerInfo = {
        position: obj.coords[0][0].slice(0, 2),
        color: (isFirst) ? startMarkerColor : middleMarkerColor,
        html: populateMarkerDetails(obj, units)
    };
    layer.markers?.push(marker);

    if (isLast) {
        let innerHTML = '';
        if(obj.hotels) {
            let hotel = obj.hotels[0];
            if (hotel.name && hotel.address) {
                innerHTML += `<h1>${hotel.name}</h1><address>${hotel.address}</address>`;
            }
            if (hotel.tel) {
                innerHTML += `<p>Telephone: <a href="tel:${hotel.tel}">${hotel.tel}</a></p>`;
            }
            if (hotel.email) {
                innerHTML += `<p>Email: <a href="mailto:${hotel.email}">${hotel.email}</a></p>`;
            }
        } else {
            if(obj.finishAddress) {
                innerHTML = obj.finishAddress.display;
            }
        }

        let html = dayName === '' || innerHTML === '' ? '<h1>The end!</h1>' : innerHTML;
        marker = {
            //ensure that we only pass lat long (not elevation and distance)
            position: obj.coords[obj.coords.length - 1][obj.coords[obj.coords.length - 1].length - 1].slice(0, 2),
            color: finishMarkerColor,
            html: html
        };
        layer.markers?.push(marker);
    }

    layer.polylines?.push(polyline);
    return layer;
}

// mode = day / route
export function getLayers(caderFactData: CaderFactData, units: string, mode: string, dayName: string) {
    var layerGroups: LayerGroupData[] = [];
    var waypointsLayers: { [key: string]: MarkerInfo[] } = {};
    var isFirst: boolean = false;
    var isLast: boolean = false;
    const showAllDays: boolean = dayName === '';

    var isFirst = true;
    caderFactData.days.forEach((day: caderDay, dayIndex:number) => {
        if (day.checked && (showAllDays || dayName === day.name)) {
            if (mode === 'day') {
                if (day.totals && day.coords && day.routes && day.routes.length>0) {
                    isFirst = !showAllDays ? true : isFirst;
                    isLast = dayIndex === caderFactData.days.length - 1 || !showAllDays ? true : false;
                    layerGroups.push(populateLayer(day, isFirst, isLast, units, dayName))
                    isFirst = false;
                }
            } else if (mode === 'route') {
                if(day.routes) {
                    isFirst = true;
                    day.routes.forEach((route: caderRoute, routeIndex:number) => {
                        isLast = (dayIndex === caderFactData.days.length - 1 || !showAllDays) && routeIndex === day.routes!.length - 1 ? true : false;
                        if (route.totals && route.coords) {
                            layerGroups.push(populateLayer(route, isFirst, isLast, units, dayName))
                        }
                        isFirst = false;
                    });
                }
            }

            // add waypoints to array
            if (day.waypoints) {
                for (let wpt of day.waypoints) {
                    // If the type does not exist in waypointsLayers, initialize it as an empty array
                    if (!waypointsLayers[wpt.type]) {
                        waypointsLayers[wpt.type] = [];
                    }

                    let marker: MarkerInfo = {
                        position: wpt.position as [number, number],
                        color: "blue",
                        html: "<h1>" + wpt.type + "</h1><div>" + wpt.name + "</div>"
                    }
                    // Push the MarkerInfo object into the array corresponding to the type
                    waypointsLayers[wpt.type].push(marker);
                }
            }
        }
    });
    layerGroups.sort((a, b) => a.name.localeCompare(b.name));

    // add the Waypoint layers
    for (let key in waypointsLayers) {
        var layerGroup: LayerGroupData = { name: key, markers: [], checked: false };

        for (let waypoint of waypointsLayers[key]) {
            layerGroup.markers?.push(waypoint);
        }

        layerGroups.push(layerGroup);
    }

    return layerGroups;
}

export async function getGPXData(caderFactData: CaderFactData, mode: string) {
    await Promise.all(caderFactData.days.map(async (day: caderDay) => {
        if (day.routes) {
            await Promise.all(day.routes.map(async (route: caderRoute) => {
                route.coords = [];
                route.coords[0] = [];
                route.totals = initiateTotals();

                // Use a for loop to process each GPX file sequentially
                for (const gpx of route.gpxFiles) {
                    let parseGPXData: any = await gpxFunctions.parseGpxFile('/gpx/' + gpx.file, 'metric');
                    let mergedGPXData: any = await gpxFunctions.mergeTracks(parseGPXData);
                    mergedGPXData.flat().forEach((item: any) => {
                        route.coords![0].push(item);
                    });
                    console.log(gpx.file + " loaded");
                    route.waypoints = parseGPXData[0].waypoints;

                    updateTotals(route.totals!, parseGPXData[0].totals);

                }
            }));
        }
    }));
}

export async function getCurrencies(caderFactData:CaderFactData) {
    interface ApiResponse {
        data: {
            [key: string]: number;
        };
    }
    
    interface Currency {
        name: string;
        value: number;
    }

    try {
        let currencies: Currency[] = [];
        const response: ApiResponse = await axios.get(`https://caderfact.garcad.cymru/api-middleware/api-cacher.php?api=freecurrencyapi`);
        for (const [key, value] of Object.entries(response.data.data)) {
            // Format the value to 3 decimal places
            const formattedValue = Math.round(value * 1000) / 1000;
            // Append the object to data.currencies
            currencies.push({ name: key, value: formattedValue });
        }
        caderFactData.currencies = currencies;
    } catch (error) {
        console.error(`Error fetching currency data:`, error);
    }
}