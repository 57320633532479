import { caderHotel } from '../types/types-caderFact';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHotel, faComment, faBed, faCutlery } from '@fortawesome/free-solid-svg-icons';

const Hotel = (props: { hotels?: caderHotel[] }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [selectedHotel, setSelectedHotel] = useState<string | null>(null);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        if (props.hotels && props.hotels.length > 0) {
            setSelectedHotel(props.hotels[0].name);
        }
    }, [props.hotels]);

    if (!props.hotels) {
        return <></>;
    }

    return (
        <div className="tile secondary w3-border">
            <div className="title" role="button" tabIndex={0} onClick={toggleExpand}>
                <FontAwesomeIcon aria-hidden="true" icon={faHotel} className="icon" />
                <h3>Accommodation</h3>
            </div>
            <div className={`content ${isExpanded ? 'expanded' : ''}`}>
                {props.hotels.length > 1 && (
                    <nav id="country-menu" className="w3-bar sub-menu primary">
                        {props.hotels.map((hotel, index) => (
                            <button 
                                key={index} 
                                onClick={() => setSelectedHotel(hotel.name)} 
                                className={`w3-bar-item w3-button ${selectedHotel === hotel.name ? 'active' : ''}`}
                            >
                                {hotel.name}
                            </button>
                        ))}
                    </nav>
                )}
                {selectedHotel && props.hotels.map((hotel, index) => {
                    if (hotel.name === selectedHotel) {
                        return (
                            <div key={index}>
                                <h4>{hotel.name}</h4>
                                <address>{hotel.address}</address>
                                {hotel.tel && (
                                    <p>Telephone: <a href={`tel:${hotel.tel}`}>{hotel.tel}</a></p>
                                )}
                                {hotel.email && (
                                    <p>Email: <a href={`mailto:${hotel.email}`}>{hotel.email}</a></p>
                                )}
                                <p>Cost: {hotel.cost.amount.toFixed(2)}{hotel.cost.currency}</p>
                                <h5><FontAwesomeIcon aria-hidden="true" icon={faBed} className="icon" /> Rooms</h5>
                                <ul>
                                    {hotel.rooms.map((room, index) => (
                                        <li key={index}>{room}</li>
                                    ))}
                                </ul>
                                <h5><FontAwesomeIcon aria-hidden="true" icon={faCutlery} className="icon" /> Facilities</h5>
                                <dl>
                                    {hotel.facilities.breakfast && (
                                        <div className="w3-row">
                                            <dt>Breakfast</dt>
                                            <dd>{hotel.facilities.breakfast}</dd>
                                        </div>
                                    )}
                                    {hotel.facilities.washing && (
                                        <div className="w3-row">
                                            <dt>Washing</dt>
                                            <dd>{hotel.facilities.washing}</dd>
                                        </div>
                                    )}
                                    {hotel.facilities.linen && (
                                        <div className="w3-row">
                                            <dt>Linen</dt>
                                            <dd>{hotel.facilities.linen}</dd>
                                        </div>
                                    )}
                                    {hotel.facilities.bikes && (
                                        <div className="w3-row">
                                            <dt>Bike storage</dt>
                                            <dd>{hotel.facilities.bikes}</dd>
                                        </div>
                                    )}
                                </dl>
                                {hotel.other && (
                                    <>
                                        <h5>Other Information</h5>
                                        <p>{hotel.other}</p>
                                    </>
                                )}
                                {hotel.reviews && (
                                    <>
                                        <h5><FontAwesomeIcon aria-hidden="true" icon={faComment} className="icon" /> Reviews</h5>
                                        {hotel.reviews.map((review, index) => (
                                            <div key={index}>
                                                <h6>{review.author}</h6>
                                                <p>{review.content}</p>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>
                        );
                    }
                    return null;
                })}
            </div>
        </div>
    );
};

export default Hotel;
