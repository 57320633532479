import { useEffect, useState } from 'react';
import axios from 'axios';
import { Country } from 'country-state-city';
import { ICountry } from 'country-state-city';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag } from '@fortawesome/free-solid-svg-icons';
import './countryInfo.scss';

interface EmbassyInfo {
    name: string;
    address: string;
    phone: string;
    contact_form: string;
    email: string;
    social_media: {
        name: string;
        url: string;
        svg: string;
    }[];
}

const CountryInfo = (props: { countries?: string[], currencies?: { name: string, value: number }[] }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
    const [embassyData, setEmbassyData] = useState<{ [key: string]: EmbassyInfo }>({});

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        if (!props.countries) return;

        const fetchEmbassyInfo = async (countryCode: string) => {
            try {
                const response = await axios.get(`https://caderfact.garcad.cymru/api-middleware/api-cacher.php?api=embassy-scraper&countryCode=${countryCode}`);
                setEmbassyData(prevState => ({
                    ...prevState,
                    [countryCode]: response.data
                }));
            } catch (error) {
                console.error(`Error fetching embassy data for ${countryCode}:`, error);
                setEmbassyData(prevState => ({
                    ...prevState,
                    [countryCode]: {
                        name: 'N/A',
                        address: 'N/A',
                        phone: 'N/A',
                        contact_form: 'N/A',
                        email: 'N/A',
                        social_media: []
                    }
                }));
            }
        };

        props.countries.forEach(countryCode => {
            fetchEmbassyInfo(countryCode);
        });

        // Set the first country as selected when the component mounts
        if (props.countries.length > 0) {
            const firstCountryCode = props.countries[0];
            setSelectedCountry(firstCountryCode.toUpperCase());
        }
    }, [props.countries]);

    if (!props.countries) {
        return <div>Can't find countries.</div>;
    }

    const getCurrencyValue = (currencyCode: string): string => {
        const currency = (props.currencies ?? []).find(c => c.name === currencyCode);
        return currency ? `1 GBP = ${currency.value} ${currencyCode}` : 'N/A';
    };

    const countryDetails = props.countries.map((countryCode) => {
        const country: ICountry | undefined = Country.getCountryByCode(countryCode.toUpperCase());

        if (!country) {
            return {
                name: `Country code ${countryCode.toUpperCase()} not found`,
                flag: 'N/A',
                isoCode: 'N/A',
                currency: 'N/A',
                currencyValue: 'N/A',
                embassy: null
            };
        }

        return {
            name: country.name,
            flag: country.flag,  // Assuming the country object has a flag property
            isoCode: country.isoCode,
            currency: country.currency,
            currencyValue: getCurrencyValue(country.currency),
            embassy: embassyData[countryCode]
        };
    });

    return (
        <>
        {props.countries.length > 0 ? (
            <div className="tile secondary w3-border">
                <div className="title" role="button" tabIndex={0} onClick={toggleExpand}>
                    <FontAwesomeIcon aria-hidden="true" icon={faFlag} className="icon" />
                    <h3>Country Information</h3>
                </div>
                <div className={`content ${isExpanded ? 'expanded' : ''}`}>
                    {props.countries.length > 1 && (
                        <nav id="country-menu" className="w3-bar sub-menu primary">
                            {countryDetails.map((country, index) => (
                                <button key={index} onClick={() => setSelectedCountry(country.isoCode)} className="w3-bar-item w3-button">{country.name}</button>
                            ))}
                        </nav>
                    )}
                    {selectedCountry && countryDetails.map((country, index) => (
                        country.isoCode === selectedCountry && (
                            <div key={index}>
                                <h4>{country.name}</h4>
                                <dl>
                                    <div className="w3-row">
                                        <dt>Flag:</dt>
                                        <dd>{country.flag}</dd>
                                    </div>
                                    <div className="w3-row">
                                        <dt>Currency:</dt>
                                        <dd>{country.currencyValue}</dd>
                                    </div>
                                </dl>
                                {country.embassy && (
                                    <>
                                        <h5>Embassy Information</h5>
                                        <dl>
                                            <div className="w3-row">
                                                <dt>Name:</dt>
                                                <dd>{country.embassy.name}</dd>
                                            </div>
                                            <div className="w3-row">
                                                <dt>Address:</dt>
                                                <dd>{country.embassy.address}</dd>
                                            </div>
                                            <div className="w3-row">
                                                <dt>Phone:</dt>
                                                <dd>{country.embassy.phone}</dd>
                                            </div>
                                            <div className="w3-row">
                                                <dt>Contact Form:</dt>
                                                <dd><a href={country.embassy.contact_form}>{country.embassy.contact_form}</a></dd>
                                            </div>
                                            <div className="w3-row">
                                                <dt>Email:</dt>
                                                <dd><a href={`mailto:${country.embassy.email}`}>{country.embassy.email}</a></dd>
                                            </div>
                                        </dl>
                                        {country.embassy.social_media && country.embassy.social_media.length > 0 && (
                                            <div className="w3-row">
                                                {country.embassy.social_media.map((social, socialIndex) => (
                                                    <a href={social.url} key={socialIndex} className="social-link" title={social.name}>
                                                        <span className="w3-hide">{social.name}</span>
                                                        {social.svg && <span dangerouslySetInnerHTML={{ __html: social.svg.replace('width="0" height="0"', 'width="2rem" height="2rem"') }} />}
                                                    </a>
                                                ))}
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        )
                    ))}
                </div>
            </div>
            ) : null}
        </>
    );
};

export default CountryInfo;
