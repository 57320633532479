import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

const Info = (props: { info?: { title: string, content: string }[] }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [selectedInfo, setSelectedInfo] = useState<string | null>(null);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        if (props.info && props.info.length > 0) {
            setSelectedInfo(props.info[0].title);
        }
    }, [props.info]);
    
    if (!props.info || props.info.length === 0) {
        return <></>;
    } 

    return (
        <div className="tile secondary w3-border">
            <div className="title" role="button" tabIndex={0} onClick={toggleExpand}>
                <FontAwesomeIcon aria-hidden="true" icon={faCircleInfo} className="icon" />
                <h3>Important information</h3>
            </div>
            <div className={`content ${isExpanded ? 'expanded' : ''}`}>
                {props.info.length > 1 && (
                    <nav id="info-menu" className="w3-bar sub-menu primary">
                        {props.info.map((item, index) => (
                            <button key={index} onClick={() => setSelectedInfo(item.title)} className="w3-bar-item w3-button">{item.title}</button>
                        ))}
                    </nav>
                )}


                {selectedInfo && props.info.map((item, index) => (
                    item.title === selectedInfo && (
                        <div key={index}>
                            <h4>{item.title}</h4>
                            <div dangerouslySetInnerHTML={{ __html: item.content }} />
                        </div>
                    )
                ))}
            </div>
        </div>
    );
};

export default Info;
