import React, { useEffect, useState } from "react";
import ToggleSwitch from './toggleSwitch';
import CaderRouteOverview from './caderRouteOverview';
import CaderDay from './caderDay';
import Footer from './footer';

import { LayerGroupData } from '../types/types-map';
import { getLayers, getGPXData, reCalcTotals, convertUnits, getCurrencies } from '../functions/parseCaderInfo';
import { LatLngExpression } from 'leaflet';
import { elevationDataCollection } from '../types/types-caderFact';
import { Totals } from '../types/types-gpx';

const CaderFactApp = (props: { 
    defaultUnits: string, 
    defaultMapMode: string, 
    defaultDarkMode: string, 
    elevationData: elevationDataCollection, 
    dayLayerGroups: LayerGroupData[], 
    routeLayerGroups: LayerGroupData[], 
    caderFactData: any,
    center: LatLngExpression
}) => {
    const [center, setCenter] = useState<LatLngExpression>(props.center);
    const [units, setUnits] = useState<string>(props.defaultUnits);
    const [mapMode, setMapMode] = useState<string>(props.defaultMapMode);
    const [darkMode, setDarkMode] = useState<string>(props.defaultDarkMode);
    const [routeTotals, setRouteTotals] = useState<Totals>(props.caderFactData.totals || { /* Default structure */ });

    // Helper function to format date to YYYY-MM-DD
    const formatDate = (date: Date): string => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    // Determine initial selectedNav based on the current date
    const currentDate = formatDate(new Date());
    const initialNav = props.caderFactData.days?.find((day: any) => formatDate(new Date(day.date)) === currentDate)?.name || 'route-overview';
    const [selectedNav, setSelectedNav] = useState<string>(initialNav);

    useEffect(() => {
        if (props.dayLayerGroups.length > 0 && props.dayLayerGroups[0].polylines && props.dayLayerGroups[0].polylines.length > 0) {
            setCenter(props.dayLayerGroups[0].polylines[0].gpxInfo!.coords[0][0]);
        }
    }, [units, mapMode, props.dayLayerGroups]);

    const handleToggleUnits = async () => {
        const newUnits = (units === 'metric') ? 'imperial' : 'metric';
        setUnits(newUnits);
        await convertUnits(props.caderFactData, newUnits);
        reCalcTotals(props.caderFactData, setRouteTotals);
    };

    const handleToggleDark = async () => {
        document.body.classList.remove(darkMode);
        const newDarkMode = (darkMode === 'light') ? 'dark' : 'light';
        setDarkMode(newDarkMode);
        document.body.classList.add(newDarkMode);
    };

    const handleToggleMode = async () => {
        const newMapMode = (mapMode === 'day') ? 'route' : 'day';
        setMapMode(newMapMode);
    };

    const handleNavClick = (navName: string) => {
        setSelectedNav(navName);
        toggleNav();
    };

    const toggleNav = () => {
        const x = document.getElementById("cader-menu");
        if (x?.classList.contains("show")) {
            x.classList.remove("show");
        } else {
            x?.classList.add("show");
        }
    };

    useEffect(() => {
        document.body.classList.add(darkMode);
    }, [darkMode]);

    return (
        <>
            <header>
                <div className="logo">
                    <img src='/caderman.jpg' alt='Caderman' className='w3-image w3-center' />
                    <h1 className="w3-hide-small w3-margin-left">CaderFactApp</h1>
                </div>
                <div className="toggle-container">
                    {/*<ToggleSwitch 
                        id="unitsSwitch"
                        name="Imperial units"
                        default={false}
                        onToggle={handleToggleUnits} />*/}
                    {/*<ToggleSwitch 
                        id="modeSwitch"
                        name="Route mode"
                        default={false}
                        onToggle={handleToggleMode} />*/}
                    <ToggleSwitch 
                        id="darkSwitch"
                        name="Dark mode"
                        default={false}
                        onToggle={handleToggleDark} />
                </div>
                <div id="menu-btn" className="w3-hide-medium w3-hide-large w3-center">
                    <button className="w3-button w3-xlarge" onClick={toggleNav}>☰</button>
                </div>
                <nav id="cader-menu" className="w3-bar">
                    <button onClick={() => handleNavClick('route-overview')} className="w3-bar-item w3-button w3-mobile">Overview</button>
                    {props.caderFactData.days?.map((day: any, dayIndex: number) => (
                        <button className="w3-bar-item w3-button w3-mobile" key={dayIndex} onClick={() => handleNavClick(day.name)}>{day.name}</button>
                    ))}
                </nav>
            </header>
            <main id="maincontent">
                {selectedNav === 'route-overview' && (
                    <CaderRouteOverview
                        mapMode={mapMode}
                        routeLayerGroups={props.routeLayerGroups}
                        dayLayerGroups={props.dayLayerGroups}
                        elevationData={props.elevationData}
                        center={center}
                        routeTotals={routeTotals}
                        units={units}
                        caderFactData={props.caderFactData}
                    />
                )}
                {props.caderFactData.days?.map((day: any, dayIndex: number) => {
                    if (day.name === selectedNav) {
                        return <CaderDay 
                                    key={dayIndex}
                                    mapMode={mapMode}
                                    units={units}
                                    day={day} 
                                    caderFactData={props.caderFactData}
                                    currencies={props.caderFactData.currencies} />;
                    } else {
                        return null; // Or any other fallback if the condition is not met
                    }
                })}
            </main>
            <Footer />
        </>
    );
};

const CaderFactAppWrapper = () => {
    const params = new URLSearchParams(window.location.search);
    const mapMode = params.get('mapMode') || 'day';
    const units = params.get('units') || 'metric';
    const darkMode = 'light';

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [dayLayerGroups, setDayLayerGroups] = useState<LayerGroupData[] | null>(null);
    const [routeLayerGroups, setRouteLayerGroups] = useState<LayerGroupData[] | null>(null);
    const [caderFactData, setCaderFactData] = useState<any>(null);
    const [initialCenter, setInitialCenter] = useState<LatLngExpression | null>(null);

    useEffect(() => {
        const fetchDataAndLayers = async () => {
            try {
                const response = await fetch('/caderFactData.json');
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const data = await response.json();
                setCaderFactData(data);
                if (!data.coords || data.coords.length === 0) {
                    await getGPXData(data, mapMode);
                }
                await reCalcTotals(data, data.totals);
                if(data.settings.use_currencies_API) {
                    await getCurrencies(data);
                }
                const dayLayers = await getLayers(data, units, 'day', '');
                const routeLayers = await getLayers(data, units, 'route', '');
                setDayLayerGroups(dayLayers);
                setRouteLayerGroups(routeLayers);

                if (dayLayers.length > 0 && dayLayers[0].polylines && dayLayers[0].polylines.length > 0) {
                    setInitialCenter(dayLayers[0].polylines[0].gpxInfo!.coords[0][0]);
                }

                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            }
        };

        fetchDataAndLayers();
    }, [mapMode, units]);

    if (isLoading || !dayLayerGroups || !caderFactData || !initialCenter) {
        return (
            <div id="loader" className="w3-container">
                <div className="w3-display-middle w3-center">
                    <img className="w3-image" src="/caderman.jpg" alt="Caderman" />
                    <p>Loading...</p>
                </div>
            </div>
        );
    }

    return (
        <CaderFactApp
            defaultUnits={units}
            defaultMapMode={mapMode}
            defaultDarkMode={darkMode}
            elevationData={caderFactData.elevationData || { days: { datasets: [] }, routes: { datasets: [] } }}
            dayLayerGroups={dayLayerGroups || []}
            routeLayerGroups={routeLayerGroups || []}
            caderFactData={caderFactData}
            center={initialCenter}
        />
    );
};

export default CaderFactAppWrapper;
