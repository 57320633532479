import React, { useState } from 'react';
import './toggleSwitch.scss';

const ToggleSwitch = (props: { id:string, name:string, default: boolean, onToggle?:() => void;}) => {
    const [isChecked, setIsChecked] = useState(props.default);

    const handleChange = () => {
        setIsChecked(!isChecked);
        if (props.onToggle) {
            props.onToggle(); 
        }
    };

    return (
        <label id={props.id}>
            {props.name}
            <span className="toggleSwitch">
                <input type="checkbox" checked={isChecked} onChange={handleChange} />
                <span className="slider"></span>
            </span>
        </label>
    )
};

export default ToggleSwitch;
