import weatherCodes from './weather-codes.json';

export interface WeatherCondition {
    description: string;
    image: string;
}

export interface WeatherCode {
    day: WeatherCondition;
    night: WeatherCondition;
}

export interface WeatherCodes {
    [key: number]: WeatherCode;
}

export default weatherCodes as WeatherCodes;