import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';

const Trivia = (props: { trivia?: string[] }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    if (!props.trivia || props.trivia.length === 0) {
        return <></>;
    }

    return (
        <div className="tile primary w3-border">
            <div className="title" role="button" tabIndex={0} onClick={toggleExpand}>
                <FontAwesomeIcon aria-hidden="true" icon={faCircleQuestion} className="icon" />
                <h3>Trivia</h3>
            </div>
            <div className={`content ${isExpanded ? 'expanded' : ''}`}>
                <ul>
                    {props.trivia.map((item, index) => (
                        <li key={index} dangerouslySetInnerHTML={{ __html: item }} />
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Trivia;
