import React from 'react';
import MapLeaflet from './mapLeaflet';
import ElevationChart from './elevationChart';
import tiles from '../config/tiles';

import { getLayers } from '../functions/parseCaderInfo';

import { elevationDataCollection, caderDay, CaderFactData, locationData } from '../types/types-caderFact';
import { LayerGroupData } from '../types/types-map';

import Info from './info';
import Roles from './roles';
import Hotel from './hotel';
import CountryInfo from './countryInfo';
import Weather from './weather';
import SunTimes from './sunTimes';
import Numbeo from './numbeo';
import Trivia from './trivia';
import Gallery from './gallery';

const CaderDay = (props: { caderFactData: CaderFactData, units: string, mapMode: string, day: caderDay, currencies?: { name: string, value: number }[] }) => {

    var elevationData: elevationDataCollection = { days: { datasets: [] }, routes: { datasets: [] } };
    elevationData.days.datasets.push(props.day.elevationData!);
    if (props.day.routes) {
        props.day.routes.forEach((route) => {
            elevationData.routes.datasets.push(route.elevationData!)
        });
    }

    const hasCoords = (props.day.routes && props.day.routes.length > 0);

    const routeLayerGroups: LayerGroupData[] = getLayers(props.caderFactData, props.units, 'route', props.day.name);
    const dayLayerGroups: LayerGroupData[] = getLayers(props.caderFactData, props.units, 'day', props.day.name);
    const center = dayLayerGroups[0]?.polylines?.[0]?.gpxInfo?.coords?.[0]?.[0] ?? [52.6994,3.9081];

    const date = new Date(props.day.date);
    let formattedDate = "Invalid date";
    if (!isNaN(date.getTime())) {
        formattedDate = new Intl.DateTimeFormat('en-US', { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' }).format(date);
    }

    const locations: locationData[] = [];

    // Check if startAddress and finishAddress exist before pushing them
    if (props.day.startAddress) {
    locations.push(props.day.startAddress);
    }

    if (props.day.finishAddress) {
    locations.push(props.day.finishAddress);
    }

    return (
        <div className="caderDay">
            <h1>{props.day.name}: {formattedDate}</h1>
            <h2>{props.day.subtitle}</h2>

            {hasCoords && (
            <div className="w3-row">
                <div className="w3-half">
                    <Roles roles={props.day.roles} />
                </div>
                <div className="w3-half">
                    <CountryInfo countries={props.day.countries} currencies={props.currencies} />
                </div>
            </div>
            )}

            <div className="w3-row">
                <div className="w3-half">
                    <Hotel hotels={props.day.hotels} />
                </div>
                <div className="w3-half">
                    <Info info={props.day.info} />
                </div>
            </div>

            {hasCoords && props.mapMode === "route" && (
                <div className="routeMode">
                    <MapLeaflet
                        key='route'
                        layerGroups={routeLayerGroups}
                        tiles={tiles}
                        zoom={6}
                        center={center} />
                    <ElevationChart
                        id="routeElevation" data={elevationData!.routes} />
                </div>
            )}
            {hasCoords && props.mapMode === "day" && (
                <div className="dayMode">
                    <MapLeaflet
                        key='day'
                        layerGroups={dayLayerGroups}
                        tiles={tiles}
                        zoom={6}
                        center={center} />
                    <ElevationChart
                        id="dayElevation" data={elevationData!.days} />
                </div>
            )}
            {hasCoords && (
            <div className="w3-row">
                <div className="w3-half">
                    <Weather locations={locations} />
                </div>
                <div className="w3-half">
                    <SunTimes date={date} locations={locations} />
                </div>
            </div>
            )}
            <div className="w3-row">
                <div className="w3-half">
                    <Numbeo location={props.day.numbeoLocation} />
                </div>
                <div className="w3-half">
                    <Trivia trivia={props.day.trivia} />
                </div>
            </div>
            <div className="w3-row">
                <Gallery gallery={props.day.gallery} />
            </div>
        </div>
    );
}

export default CaderDay;
