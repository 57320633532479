import MapLeaflet from './mapLeaflet';
import ElevationChart from './elevationChart';
import RouteTotals from './routeTotals';
import tiles from '../config/tiles';

import { Totals } from '../types/types-gpx';
import { elevationDataCollection, CaderFactData } from '../types/types-caderFact';
import { LayerGroupData } from '../types/types-map';
import { LatLngExpression } from 'leaflet';

import CountdownTimer from './countdownTimer';

const CaderRouteOverview = (props: {caderFactData:CaderFactData, units: string, routeTotals?: Totals, mapMode:string, routeLayerGroups:LayerGroupData[], dayLayerGroups:LayerGroupData[], center:LatLngExpression, elevationData:elevationDataCollection}) => {

    const date = new Date(props.caderFactData.days[0].date);

    return (
        <div className="caderRoute">
            <h1>{props.caderFactData.name}</h1>
            <h2>Overview</h2>
            {date > new Date() && <CountdownTimer date={date} />}
            
            {props.mapMode === 'route' && (
            <>
                <RouteTotals units={props.units} routeTotals={props.routeTotals} />
                <MapLeaflet 
                    key='route'
                    layerGroups={props.routeLayerGroups}
                    tiles={tiles}
                    zoom={6}
                    center={props.center} />
                <ElevationChart 
                    id="routeElevation" data={props.elevationData!.routes} />
            </>
            )}
            {props.mapMode === 'day' && (
            <>
                <RouteTotals units={props.units} routeTotals={props.routeTotals} />
                <MapLeaflet 
                    key='day'
                    layerGroups={props.dayLayerGroups}
                    tiles={tiles}
                    zoom={6}
                    center={props.center} />
                <ElevationChart 
                    id="dayElevation" data={props.elevationData!.days} />
            </>
            )}
        </div>
    );
}

export default CaderRouteOverview;