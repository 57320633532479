import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import './gallery.scss';
import { imageData } from '../types/types-caderFact';

const Gallery = (props: { gallery?: imageData[] }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [slideIndex, setSlideIndex] = useState(1);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        if (props.gallery && props.gallery.length > 0) {
            showDivs(slideIndex);
        }
    }, [slideIndex, props.gallery]);

    if (!props.gallery) {
        return null;
    }

    const plusDivs = (n: number) => {
        setSlideIndex(prevIndex => {
            let newIndex = prevIndex + n;
            if (newIndex > props.gallery!.length) newIndex = 1;
            if (newIndex < 1) newIndex = props.gallery!.length;
            console.log(props.gallery!.length);
            return newIndex;
        });
    };

    const showDivs = (n: number) => {
        const slides = document.getElementsByClassName("caderSlides") as HTMLCollectionOf<HTMLElement>;
        if (slides.length > 0) {
            for (let i = 0; i < slides.length; i++) {
                slides[i].style.display = "none";
            }
            if (slides[n - 1]) {
                slides[n - 1].style.display = "block";
            }
        }
    };

    return (
        <div className="tile primary w3-border">
            <div className="title" role="button" tabIndex={0} onClick={toggleExpand}>
                <FontAwesomeIcon aria-hidden="true" icon={faImage} className="icon" />
                <h3>Gallery</h3>
            </div>
            <div className={`content no-padding ${isExpanded ? 'expanded' : ''}`}>
                <div className="w3-display-container">
                    {props.gallery.map((image, index) => (
                        <div key={index} className="w3-display-container caderSlides w3-animate-opacity">
                            <img src={image.url} alt={image.alt} />
                            <div className="w3-display-bottommiddle w3-container w3-padding-32 w3-hide-small">
                                <div className="w3-padding transparent">
                                    {image.name && <h4>{image.name}</h4>}
                                    {image.description && <p>{image.description}</p>}
                                </div>
                            </div>
                        </div>
                    ))}
                    <button aria-label="Previous" className="w3-button noselection w3-display-left transparent" onClick={() => plusDivs(-1)}>
                        <FontAwesomeIcon aria-hidden="true" icon={faArrowLeft} className="icon" />
                        <span className="w3-hide">Previous</span>
                    </button>
                    <button aria-label="Next" className="w3-button noselection w3-display-right transparent" onClick={() => plusDivs(1)}>
                        <FontAwesomeIcon aria-hidden="true" icon={faArrowRight} className="icon" />
                        <span className="w3-hide">Next</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Gallery;
