import { useState, useCallback } from "react";

import { MapContainer, TileLayer, Polyline, Marker, Popup, LayersControl, LayerGroup } from 'react-leaflet';
import L, { LatLngExpression } from "leaflet";
import { LayerGroupData, Tile } from '../types/types-map';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap } from '@fortawesome/free-solid-svg-icons';

import 'leaflet/dist/leaflet.css';
import './mapLeaflet.scss';

const MapLeaflet = (props: {layerGroups?:LayerGroupData[], tiles?:Tile[], zoom?:number, center?:LatLngExpression, onLayerChange?: (action: string, groupName: string) => void;}) => {
    const [isLoaded, setIsLoaded] = useState(false);

    const [isExpanded, setIsExpanded] = useState(true);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const mapRef = useCallback((node: any | null) => {
        if (node !== null) {
          setIsLoaded(true);
        }
      }, []);

    return (
        <div className="tile w3-border second">
            <div className="title" role="button" tabIndex={0} onClick={toggleExpand}>
                <FontAwesomeIcon aria-hidden="true" icon={faMap} className="icon" />
                <h3>Route</h3>
            </div>
            <div className={`content no-padding ${isExpanded ? 'expanded' : ''}`}>
                <MapContainer ref={mapRef} center={props.center || [51.478146, -3.183414]} zoom={props.zoom || 10} scrollWheelZoom={true} style={{ height: '80vh', width: '100wh' }}>
                    <LayersControl>
                        {props.tiles?.map((tile, index) => (
                        <LayersControl.BaseLayer key={index} checked={tile.checked} name={tile.name}>
                            <TileLayer url={tile.url} attribution={tile.attribution}/>
                        </LayersControl.BaseLayer>
                        ))}
                        {props.layerGroups?.map((group, index) => (
                        <LayersControl.Overlay  name={group.name} key={index} checked={group.checked !== false}>
                            <LayerGroup>
                            
                            {group.markers?.map((marker, markerIndex) => (
                                <Marker key={markerIndex} position={marker.position} icon={svgMarker(`${index}-${markerIndex}`, marker.color, marker.rgbSD, marker.rgbSL, marker.rgbFD, marker.rgbFL)}>
                                    <Popup className="markerPopup">
                                        <div dangerouslySetInnerHTML={{__html: marker.html || ''}} />
                                    </Popup>
                                </Marker>
                            ))}
                            {group.polylines?.map((polyline, polylineIndex) => {
                                return (
                                    <Polyline key={polylineIndex}
                                        pathOptions={{ color: polyline.color, weight: polyline.weight, opacity: 1 }}
                                        positions={polyline!.gpxInfo!.coords}
                                    />
                                );
                            })}
                            </LayerGroup>
                        </LayersControl.Overlay>
                        ))}
                    </LayersControl>
                </MapContainer>
            </div>
        </div>
    );
}

const svgMarker = (markerID:string, color?:string, rgbFillDark?:string, rgbFillLight?:string, rgbStrokeDark?:string, rgbStrokeLight?:string) => {
    const colorMap: { [key: string]: any; } = {
        'red': { rgbFD: '220,28,19', rgbFL: '234,76,70', rgbSD: '157,18,12', rgbSL: '190,65,60' },
        'green': { rgbFD: '15,137,40', rgbFL: '58,178,79', rgbSD: '7,79,22', rgbSL: '29,114,44' },
        'yellow': { rgbFD: '255,201,0', rgbFL: '255,236,31', rgbSD: '177,141,9', rgbSL: '183,170,25' },
        'orange': { rgbFD: '231,106,24', rgbFL: '221,132,37', rgbSD: '147,61,4', rgbSL: '137,79,15' },
        'blue': { rgbFD: '18,111,198', rgbFL: '76,156,209', rgbSD: '46,108,151', rgbSL: '56,131,183' },
        'black': { rgbFD: '20,20,20', rgbFL: '60,60,60', rgbSD: '0,0,0', rgbSL: '40,40,40' },
        'white': { rgbFD: '210,210,210', rgbFL: '255,255,255', rgbSD: '90,90,90', rgbSL: '130,130,130' },
    };

    color = color || 'blue';
    if (!(color in colorMap)) {
        color = 'blue';
    } 

    return L.divIcon({
        html: `
        <svg aria-label="Map marker" viewBox="0 0 820 820" version="1.1" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" style="fill-rule: evenodd; clip-rule: evenodd; stroke-linecap: round;">
            <defs>
                <linearGradient x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(2.30025e-15,-37.566,37.566,2.30025e-15,416.455,540.999)" id="leaflet-marker-fill-${markerID}">
                    <stop offset="0" stop-color="rgb(${colorMap[color].rgbFD})"/>
                    <stop offset="1" stop-color="rgb(${colorMap[color].rgbFL})"/>
                </linearGradient>
                <linearGradient x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(1.16666e-15,-19.053,19.053,1.16666e-15,414.482,522.486)" id="leaflet-marker-stroke-${markerID}">
                    <stop offset="0" stop-color="rgb(${colorMap[color].rgbSD})"/>
                    <stop offset="1" stop-color="rgb(${colorMap[color].rgbSL})"/>
                </linearGradient>
            </defs>
            <g transform="matrix(19.5417,0,0,19.5417,-7889.1,-9807.44)">
                <path d="M416.544,503.612C409.971,503.612 404.5,509.303 404.5,515.478C404.5,518.256 406.064,521.786 407.194,524.224L416.5,542.096L425.762,524.224C426.892,521.786 428.5,518.433 428.5,515.478C428.5,509.303 423.117,503.612 416.544,503.612ZM416.544,510.767C419.128,510.784 421.223,512.889 421.223,515.477C421.223,518.065 419.128,520.14 416.544,520.156C413.96,520.139 411.865,518.066 411.865,515.477C411.865,512.889 413.96,510.784 416.544,510.767Z" stroke-width="1.1px" fill="url(#leaflet-marker-fill-${markerID})" stroke="url(#leaflet-marker-stroke-${markerID})"/>
            </g>
        </svg>`,
        className: "svg-leaflet-marker",
        iconSize: [50, 50],
        iconAnchor: [15, 49]
      });
};

export default MapLeaflet;