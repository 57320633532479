import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';

const Numbeo = (props: { location?: string }) => {
    const [costOfLivingData, setCostOfLivingData] = useState<Record<string, Record<string, string>>>({});
    const [loading, setLoading] = useState(true);
    const [isExpanded, setIsExpanded] = useState(false);
    const [selectedCat, setSelectedCat] = useState<string | null>('Restaurant');

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        if (!props.location) {
            return;
        }

        const fetchData = async () => {
            setLoading(true);
            try {
                const apiUrl = `https://caderfact.garcad.cymru/api-middleware/api-cacher.php?api=numbeo-scraper&city=${props.location}`;
                const response = await axios.get(apiUrl);
                setCostOfLivingData(response.data);
            } catch (error) {
                console.error(`Error fetching data for ${props.location}:`, error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [props.location]);

    if (!props.location) {
        return <></>;
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="tile primary w3-border">
            <div className="title" role="button" tabIndex={0} onClick={toggleExpand}>
                <FontAwesomeIcon aria-hidden="true" icon={faCreditCard} className="icon" />
                <h3>Cost of living in {props.location}</h3>
            </div>
            <div className={`content ${isExpanded ? 'expanded' : ''}`}>
                <nav id="info-menu" className="w3-bar sub-menu secondary">
                {Object.entries(costOfLivingData).map(([category, items], index) => (
                    <button key={index} onClick={() => setSelectedCat(category)} className="w3-bar-item w3-button">{category}</button>
                ))}
                </nav>
                {Object.entries(costOfLivingData).map(([category, items]) => (
                    category === selectedCat && (
                        <div key={category} className="category">
                            <h4>{category}</h4>
                            <dl>
                                {Object.entries(items).map(([key, value]) => (
                                    <React.Fragment key={key}>
                                        <div className="w3-row">
                                            <dt>{key}:</dt>
                                            <dd>{value} GBP</dd>
                                        </div>
                                    </React.Fragment>
                                ))}
                            </dl>
                        </div>
                    )
                ))}
            </div>
        </div>
    );
};

export default Numbeo;
