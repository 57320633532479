import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { locationData } from '../types/types-caderFact';
import weatherCodes from '../weather-codes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudSunRain } from '@fortawesome/free-solid-svg-icons';

interface CurrentWeather {
    time: string;
    temperature_2m: number;
    relative_humidity_2m: number;
    apparent_temperature: number;
    precipitation: number;
    weather_code: number;
    cloud_cover: number;
    wind_speed_10m: number;
    wind_direction_10m: number;
    wind_gusts_10m: number;
}

interface DailyWeather {
    time: string[];
    weather_code: number[];
    temperature_2m_max: number[];
    temperature_2m_min: number[];
    apparent_temperature_max: number[];
    apparent_temperature_min: number[];
    sunshine_duration: number[];
    precipitation_sum: number[];
    precipitation_probability_max: number[];
    wind_speed_10m_max: number[];
    wind_direction_10m_dominant: number[];
}

const Weather = (props: { locations: locationData[] }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState<string | null>(null);
    const [selectedSubmenu, setSelectedSubmenu] = useState<'current' | number | null>(null);
    const [weatherData, setWeatherData] = useState<CurrentWeather[]>([]);
    const [dailyWeather, setDailyWeather] = useState<DailyWeather[]>([]);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        const fetchData = async () => {
            const promises = props.locations.map(location => {
                const apiUrl = `https://caderfact.garcad.cymru/api-middleware/api-cacher.php?api=open-meteo&latitude=${location.lat}&longitude=${location.long}&current=temperature_2m,relative_humidity_2m,apparent_temperature,precipitation,weather_code,cloud_cover,wind_speed_10m,wind_direction_10m,wind_gusts_10m&daily=weather_code,temperature_2m_max,temperature_2m_min,apparent_temperature_max,apparent_temperature_min,sunshine_duration,precipitation_sum,precipitation_probability_max,wind_speed_10m_max,wind_direction_10m_dominant&wind_speed_unit=mph&timezone=auto`;

                return axios.get(apiUrl)
                    .then(response => response.data)
                    .catch(error => {
                        console.error(`Error fetching weather data for ${location.display}:`, error);
                        return null;
                    });
            });

            // Wait for all requests to complete
            const results = await Promise.all(promises);

            // Assuming the API returns the current weather data as the first item
            const currentWeatherData = results.map(result => result?.current);
            const dailyWeatherData = results.map(result => result?.daily);

            setWeatherData(currentWeatherData.filter(Boolean) as CurrentWeather[]); // Filter out null values
            setDailyWeather(dailyWeatherData.filter(Boolean) as DailyWeather[]); // Filter out null values
        };

        fetchData();
    }, [props.locations]);

    useEffect(() => {
        if (props.locations && props.locations.length > 0) {
            setSelectedLocation(props.locations[0].display);
            setSelectedSubmenu('current');
        }
    }, [props.locations]);

    const getWeatherCondition = (code: number, isDay: boolean) => {
        const condition = weatherCodes[code];
        return condition ? condition[isDay ? 'day' : 'night'] : null;
    };

    const getDayOfWeek = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', { weekday: 'long' });
    };

    const getReadableTime = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        });
    };

    return (
        <>
        {props.locations.length > 0 ? (
        <div className="tile primary w3-border">
            <div className="title" role="button" tabIndex={0} onClick={toggleExpand}>
                <FontAwesomeIcon icon={faCloudSunRain} className="icon" />
                <h3>Weather</h3>
            </div>
            <div className={`content ${isExpanded ? 'expanded' : ''}`}>
                {props.locations.length > 1 && (
                    <nav id="weather-menu" className="w3-bar sub-menu secondary">
                        {props.locations.map((item, index) => (
                            <button 
                                key={index} 
                                onClick={() => { 
                                    setSelectedLocation(item.display); 
                                    setSelectedSubmenu('current');
                                }} 
                                className={`w3-bar-item w3-button ${selectedLocation === item.display ? 'active' : ''}`}
                            >
                                {item.display.split(',')[0]}
                            </button>
                        ))}
                    </nav>
                )}

                {selectedLocation && props.locations.map((location, locIndex) => {
                    if (location.display === selectedLocation) {
                        const currentWeather = weatherData[locIndex];
                        const dailyWeatherData = dailyWeather[locIndex];

                        return (
                            <div key={locIndex}>
                                <h4>{location.display}</h4>
                                
                                <nav className="w3-bar sub-menu secondary">
                                    <button 
                                        onClick={() => setSelectedSubmenu('current')} 
                                        className={`w3-bar-item w3-button ${selectedSubmenu === 'current' ? 'active' : ''}`}
                                    >
                                        Today
                                    </button>
                                    {dailyWeatherData && dailyWeatherData.time.map((date, idx) => (
                                        <button 
                                            key={idx}
                                            onClick={() => setSelectedSubmenu(idx)} 
                                            className={`w3-bar-item w3-button ${selectedSubmenu === idx ? 'active' : ''}`}
                                        >
                                            {getDayOfWeek(date)}
                                        </button>
                                    ))}
                                </nav>

                                {selectedSubmenu === 'current' && currentWeather && (
                                    <>
                                        <h5>Today's weather: {getReadableTime(currentWeather.time)}</h5>
                                        <div className="w3-row" style={{ textAlign: 'center' }}>
                                            {getWeatherCondition(currentWeather.weather_code, true) ? (
                                                <img src={getWeatherCondition(currentWeather.weather_code, true)?.image} alt={getWeatherCondition(currentWeather.weather_code, true)?.description} />
                                            ) : (
                                                <span>{currentWeather.weather_code}</span>
                                            )}
                                        </div>
                                        <dl>
                                            <div className="w3-row">
                                                <dt>Temperature</dt>
                                                <dd>{currentWeather.temperature_2m} °C</dd>
                                            </div>
                                            <div className="w3-row">
                                                <dt>Humidity</dt>
                                                <dd>{currentWeather.relative_humidity_2m} %</dd>
                                            </div>
                                            <div className="w3-row">
                                                <dt>Apparent Temperature</dt>
                                                <dd>{currentWeather.apparent_temperature} °C</dd>
                                            </div>
                                            <div className="w3-row">
                                                <dt>Precipitation</dt>
                                                <dd>{currentWeather.precipitation} mm</dd>
                                            </div>
                                            <div className="w3-row">
                                                <dt>Cloud Cover</dt>
                                                <dd>{currentWeather.cloud_cover} %</dd>
                                            </div>
                                            <div className="w3-row">
                                                <dt>Wind Speed</dt>
                                                <dd>{currentWeather.wind_speed_10m} mph</dd>
                                            </div>
                                            <div className="w3-row">
                                                <dt>Wind Direction</dt>
                                                <dd>{currentWeather.wind_direction_10m} °</dd>
                                            </div>
                                            <div className="w3-row">
                                                <dt>Wind Gusts</dt>
                                                <dd>{currentWeather.wind_gusts_10m} mph</dd>
                                            </div>
                                        </dl>
                                    </>
                                )}

                                {typeof selectedSubmenu === 'number' && dailyWeatherData && (
                                    <div>
                                        <h5>{getDayOfWeek(dailyWeatherData.time[selectedSubmenu])}</h5>
                                        <div className="w3-row" style={{ textAlign: 'center' }}>
                                            {getWeatherCondition(dailyWeatherData.weather_code[selectedSubmenu], true) ? (
                                                <img src={getWeatherCondition(dailyWeatherData.weather_code[selectedSubmenu], true)?.image} alt={getWeatherCondition(dailyWeatherData.weather_code[selectedSubmenu], true)?.description} />
                                            ) : (
                                                <span>{dailyWeatherData.weather_code[selectedSubmenu]}</span>
                                            )}
                                        </div>
                                        <dl>
                                            <div className="w3-row">
                                                <dt>Max Temperature</dt>
                                                <dd>{dailyWeatherData.temperature_2m_max[selectedSubmenu]} °C</dd>
                                            </div>
                                            <div className="w3-row">
                                                <dt>Min Temperature</dt>
                                                <dd>{dailyWeatherData.temperature_2m_min[selectedSubmenu]} °C</dd>
                                            </div>
                                            <div className="w3-row">
                                                <dt>Max Apparent Temperature</dt>
                                                <dd>{dailyWeatherData.apparent_temperature_max[selectedSubmenu]} °C</dd>
                                            </div>
                                            <div className="w3-row">
                                                <dt>Min Apparent Temperature</dt>
                                                <dd>{dailyWeatherData.apparent_temperature_min[selectedSubmenu]} °C</dd>
                                            </div>
                                            <div className="w3-row">
                                                <dt>Sunshine Duration</dt>
                                                <dd>{dailyWeatherData.sunshine_duration[selectedSubmenu]} s</dd>
                                            </div>
                                            <div className="w3-row">
                                                <dt>Precipitation Sum</dt>
                                                <dd>{dailyWeatherData.precipitation_sum[selectedSubmenu]} mm</dd>
                                            </div>
                                            <div className="w3-row">
                                                <dt>Precipitation Probability Max</dt>
                                                <dd>{dailyWeatherData.precipitation_probability_max[selectedSubmenu]} %</dd>
                                            </div>
                                            <div className="w3-row">
                                                <dt>Max Wind Speed</dt>
                                                <dd>{dailyWeatherData.wind_speed_10m_max[selectedSubmenu]} mph</dd>
                                            </div>
                                            <div className="w3-row">
                                                <dt>Dominant Wind Direction</dt>
                                                <dd>{dailyWeatherData.wind_direction_10m_dominant[selectedSubmenu]} °</dd>
                                            </div>
                                        </dl>
                                    </div>
                                )}
                            </div>
                        );
                    }
                    return null;
                })}
            </div>
        </div>
        ) : null}
        </>
    );
};

export default Weather;
