import { caderRoles } from '../types/types-caderFact';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faSignsPost, faCoins, faBasketShopping } from '@fortawesome/free-solid-svg-icons';

const Roles = (props: { roles?: caderRoles}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    if (!props.roles) {
        return <></>;
    }
    
    return (
        <div className="tile secondary w3-border">
            <div className="title" role="button" tabIndex={0} onClick={toggleExpand}>
                <FontAwesomeIcon aria-hidden="true" icon={faUsers} className="icon" />
                <h3>Roles</h3>
            </div>
            <div className={`content ${isExpanded ? 'expanded' : ''}`}>
                <dl>
                    <div className="w3-row">
                        <dt><FontAwesomeIcon aria-hidden="true" icon={faSignsPost} className="icon" /> Cadernav:</dt>
                        <dd>{props.roles.nav}</dd>
                    </div>
                    <div className="w3-row">
                        <dt><FontAwesomeIcon aria-hidden="true" icon={faCoins} className="icon" /> Kittymaster:</dt>
                        <dd>{props.roles.kitty}</dd>
                    </div>
                    <div className="w3-row">
                        <dt><FontAwesomeIcon aria-hidden="true" icon={faBasketShopping} className="icon" /> Quartermaster:</dt>
                        <dd>{props.roles.quarter}</dd>
                    </div>
                </dl>
            </div>
        </div>
    );
};

export default Roles;