import Countdown from 'react-countdown';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';

const CountdownTimer = (props: { date?: Date}) => {
    const [isExpanded, setIsExpanded] = useState(true);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    if (!props.date) {
        return <></>;
    }
    
    return (
        <div className="tile secondary w3-border">
            <div className="title" role="button" tabIndex={0} onClick={toggleExpand}>
                <FontAwesomeIcon aria-hidden="true" icon={faClock} className="icon" />
                <h3>Countdown</h3>
            </div>
            <div className={`w3-center content ${isExpanded ? 'expanded' : ''}`}>
                <Countdown className='w3-jumbo' date={props.date} />
            </div>
        </div>
    );
};

export default CountdownTimer;