import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMountainSun } from '@fortawesome/free-solid-svg-icons';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Scatter } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Elevation',
    },
  },
};

const ElevationChart = (props: {id:string, data:any}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
      setIsExpanded(!isExpanded);
  };
  
  return (
    <div className="tile w3-border second">
        <div className="title" role="button" tabIndex={0} onClick={toggleExpand}>
            <FontAwesomeIcon aria-hidden="true" icon={faMountainSun} className="icon" />
            <h3>Elevation</h3>
        </div>
        <div className={`content ${isExpanded ? 'expanded' : ''}`}>
          <Scatter options={options} data={props.data} aria-label="Elevation chart" />;
        </div>
    </div>
);
}

export default ElevationChart;