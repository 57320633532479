import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faSignsPost, faMountain, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { Totals } from '../types/types-gpx';

const RouteTotals = (props: { units: string, routeTotals?: Totals }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [totals, setTotals] = useState<Totals | undefined>(props.routeTotals);

    useEffect(() => {
        setTotals(props.routeTotals);
    }, [props.routeTotals]);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    if (!totals) {
        return null; // Render nothing if routeTotals is not defined
    }

    const distanceUnit = props.units === 'imperial' ? 'miles' : 'km';
    const elevationUnit = props.units === 'imperial' ? 'ft' : 'm';

    const formatNumber = (num: number) => parseFloat(num.toFixed(1));

    return (
        <div className="tile secondary w3-border">
            <div className="title" role="button" tabIndex={0} onClick={toggleExpand}>
                <FontAwesomeIcon aria-hidden="true" icon={faChartLine} className="icon" />
                <h3>Route totals</h3>
            </div>
            <div className={`content ${isExpanded ? 'expanded' : ''}`}>
                <dl>
                    <div className="w3-row">
                        <div className="w3-third">
                            <dt><FontAwesomeIcon aria-hidden="true" icon={faSignsPost} className="icon" /> Distance:</dt>
                            <dd>{formatNumber(totals.distance)} {distanceUnit}</dd>
                        </div>
                        <div className="w3-third">
                            <dt><FontAwesomeIcon aria-hidden="true" icon={faChartLine} className="icon" />
                                <FontAwesomeIcon aria-hidden="true" icon={faPlus} className="icon" /> Elevation gain:</dt>
                            <dd>{formatNumber(totals.elevationGain)} {elevationUnit}</dd>
                        </div>
                        <div className="w3-third">
                            <dt><FontAwesomeIcon aria-hidden="true" icon={faMountain} className="icon" />
                                <FontAwesomeIcon aria-hidden="true" icon={faPlus} className="icon" /> Max elevation:</dt>
                            <dd>{formatNumber(totals.maxElevation)} {elevationUnit}</dd>
                        </div>
                    </div>
                    <div className="w3-row">
                        <div className="w3-third">
                            <dt><FontAwesomeIcon aria-hidden="true" icon={faChartLine} className="icon" /> Net elevation:</dt>
                            <dd>{formatNumber(totals.elevationNet)} {elevationUnit}</dd>
                        </div>
                        <div className="w3-third">
                            <dt><FontAwesomeIcon aria-hidden="true" icon={faChartLine} className="icon" />
                                <FontAwesomeIcon aria-hidden="true" icon={faMinus} className="icon" /> Elevation loss:</dt>
                            <dd>{formatNumber(totals.elevationLoss)} {elevationUnit}</dd>
                        </div>
                        <div className="w3-third">
                            <dt><FontAwesomeIcon aria-hidden="true" icon={faMountain} className="icon" />
                                <FontAwesomeIcon aria-hidden="true" icon={faMinus} className="icon" /> Min elevation:</dt>
                            <dd>{formatNumber(totals.minElevation)} {elevationUnit}</dd>
                        </div>
                    </div>
                </dl>
            </div>
        </div>
    );
};

export default RouteTotals;
